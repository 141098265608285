<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row>
            <v-col cols="12">
              <v-img
                  height="70"
                  src="@/assets/images/voffice.png"
                  contain
                  style="margin-top: 30px; margin-bottom: 30px;"
              ></v-img>
            </v-col>
          </v-row>

          <v-toolbar dark color="primary" flat>
            <v-toolbar-title>{{ $t('settings.lang_vofficeInterface') }}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon >
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>

          <v-tabs>
            <v-tab>{{ $t('settings.lang_emailSettings') }}</v-tab>
            <v-tab>{{ $t('settings.lang_apiKey') }}</v-tab>

            <v-tab-item>
              <VofficeEmailSettingsComponent/>
            </v-tab-item>

            <v-tab-item>
              <VofficeAPIkey/>
            </v-tab-item>

          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VofficeEmailSettingsComponent from "@/components/settings/moduleSettings/voffice/VofficeEmailSettingsComponent";
import VofficeAPIkey from "@/components/settings/moduleSettings/voffice/VofficeAPIkey";
export default {
  name: "VofficeComponent",
  components: {VofficeAPIkey, VofficeEmailSettingsComponent}
}
</script>

<style scoped>

</style>