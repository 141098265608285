<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row>
            <v-col cols="12">
              <v-img
                  height="70"
                  src="@/assets/images/dirmeier.png"
                  contain
                  style="margin-top: 30px; margin-bottom: 30px;"
              ></v-img>
            </v-col>
          </v-row>
          <v-divider class="ma-0"/>
          <v-toolbar flat color="primary" dark>
            <v-toolbar-title>Dirmeier Schanktechnik</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="getData()">
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider class="ma-0"/>
          <v-card-text>
            <v-row>
              <v-col cols="10">
                <v-text-field :disabled="loading" hide-details outlined dense :label="$t('settings.lang_urlOfTheDispensingSystem')" :placeholder="$t('settings.lang_urlOfTheDispensingSystem')" v-model="url"></v-text-field>
              </v-col>
              <v-col cols="2" align-self="center">
                <v-switch class="mt-0"  v-model="status" :label="$t('settings.lang_settings_activ')" hide-details inset></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="text-right">
            <v-spacer/>
            <v-btn color="success" @click="saveData" :disabled="loading"  :loading="loading">{{ $t('generic.lang_save') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
  name: "DirmeierComponent",
  data(){
    return{
      url:'',
      status:false,
      loading:false,
    }
  },
  methods:{
    getData(){
      this.loading = true;

      this.axios.post(ENDPOINTS.DIRMEIER.SETTINGS.GET, {

      }).then((res) => {
        if(res.data.status) {
          this.url = res.data.dirmeierSchankanlageURL;
          this.status = res.data.dirmeierSchankanlageAktiv;
        }else {

        }
      }).finally(() => this.loading = false)
    },
    saveData(){
      this.loading = true;

      this.axios.post(ENDPOINTS.DIRMEIER.SETTINGS.UPDATE, {
        dirmeierSchankanlageURL: this.url,
        dirmeierSchankanlageAktiv: this.status
      }).then((res) => {
        if(res.data.success) {

        }else {

        }
      }).finally(() => this.loading = false)
    },
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>

</style>
