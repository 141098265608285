<template>
  <div>
    <page-title heading="VOffice  " subheading="VOffice " :icon=icon></page-title>
    <div class="app-main__inner">
      <VofficeComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import DirmeierComponent from "@/components/settings/moduleSettings/DirmeierComponent";
import VofficeComponent from "@/components/settings/moduleSettings/voffice/VofficeComponent";
export default {
  name: "Voffice",
  components: {VofficeComponent, PageTitle},
  data(){
    return{
      icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    }
  }
}
</script>

<style scoped>

</style>
