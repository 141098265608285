<template>


  <v-container fluid>
    <v-card elevation="0">
      <v-layout>
        <v-flex md12 mt-5 mr-2 ml-5>
          {{ $t('settings.lang_enterVofficeApikey') }}<br><br>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex md12 mr-5 ml-5>
          <v-text-field outlined
                        @focus="showTouchKeyboard"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        v-model="vofficeAPIKey"
                        :label="'vOffice ' + $t('settings.lang_apiKey')"
                        autocomplete="off"
                        required
          ></v-text-field>
        </v-flex>

      </v-layout>



      <v-layout class="text-right">
        <v-flex md12 mb-5 mr-5 ml-5>
          <v-btn color="success" @click="updateData">{{ $t('generic.lang_apply') }}</v-btn>
        </v-flex>
      </v-layout>

    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options" :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>



<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";

export default {
  name: "VofficeAPIkey",
  components: {
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      vofficeAPIKey: "",
      // ----------------------------------

    }
  },
  methods: {
    getData() {
      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.VOFFICE.APIKEY.GET,{
      }).then((res) => {
        if (res.status === 200) {
          this.vofficeAPIKey = res.data.settings.vofficeAPIKey
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    updateData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.VOFFICE.APIKEY.UPDATE, {
        vofficeAPIKey: this.vofficeAPIKey
      }).then((res) => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
        this.loading_update=false;
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });

      });
    },

  },

  mounted() {
    //GET DATA
    this.getData();

  },
}
</script>